<template>
  <div>
    <a-descriptions
      title="运营商接口"
      :column="1"
      class="simiot-descriptions simiot-descriptions-max-9"
    >
      <a-descriptions-item label="内部API服务器域名">
        {{ apiAccount.api_server_domain }}
      </a-descriptions-item>

      <a-descriptions-item label="使用中国移动接口">
        {{ isUseStandardApiDisplay }}
      </a-descriptions-item>

      <a-descriptions-item label="中国移动接口版本" v-if="isUseStandardApi">
        {{ apiAccount.standard_api_version }}
      </a-descriptions-item>

      <a-descriptions-item label="API访问路径" v-if="isUseStandardApi">
        {{ apiAccount.standard_api_url }}
      </a-descriptions-item>

      <a-descriptions-item label="应用编码" v-if="isUseStandardApi">
        {{ apiAccount.app_id }}
      </a-descriptions-item>

      <a-descriptions-item label="接口密码" v-if="isUseStandardApi">
        {{ apiAccount.api_password }}
      </a-descriptions-item>

      <a-descriptions-item label="调用限频" v-if="isUseStandardApi">
        {{ apiAccount.standard_rate_display }}
      </a-descriptions-item>

      <a-descriptions-item label="使用地方移动接口">
        {{ isUseProvinceApiDisplay }}
      </a-descriptions-item>

      <a-descriptions-item label="地方接口所属区域" v-if="isUseProvinceApi">
        {{ apiAccount.province_api_type }}
      </a-descriptions-item>

      <a-descriptions-item label="配置参数" v-if="isUseProvinceApi">
        <span class="simiot-word-break-all">
          {{ apiAccount.province_api_config }}
        </span>
      </a-descriptions-item>

      <a-descriptions-item label="调用限频" v-if="isUseProvinceApi">
        {{ apiAccount.province_rate_display }}
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>

<script>
export default {
  name: 'ShowChinaMobileApiAccount',
  props: {
    apiAccount: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isUseStandardApi: false,
      isUseProvinceApi: false
    }
  },
  computed: {
    isUseStandardApiDisplay() {
      if (this.isUseStandardApi) {
        return '是'
      } else {
        return '否'
      }
    },

    isUseProvinceApiDisplay() {
      if (this.isUseProvinceApi) {
        return '是'
      } else {
        return '否'
      }
    }
  },
  created() {
    this.isUseStandardApi = this.apiAccount.is_use_standard_api
    this.isUseProvinceApi = this.apiAccount.is_use_province_api
  }
}
</script>

